import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { addLocale, locale } from "primereact/api";

import calendarTranslation from "../../shared/i18n/calendarTranslation.json";

/**
 * Funzione da utilizzare solo in DEBUG o SVILUPPO
 * @param {*} languages 
 * @param {*} namespace 
 * @param {*} key 
 * @param {*} fallbackValue 
 */
const missingKeyHandler = (languages,namespace,key, fallbackValue) => {
  console.warn(`Missing translation key: ${key}: using fallback value: ${fallbackValue}`);
}


i18next.use(initReactI18next).use(LanguageDetector).use(HttpApi).init({
  fallbackLng: "en",
  debug: true,
  missingKeyHandler: missingKeyHandler
});

//carico le traduzioni dei componenti di prime React (es calendario)
addLocale("it", calendarTranslation.it);
//imposto la lingua di prime react
locale(i18next.language);

