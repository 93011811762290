import React from "react";
import ReactDOM from "react-dom/client";

import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "/node_modules/primeflex/primeflex.css";

import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

//import reportWebVitals from "./reportWebVitals";

import "./index.css";
import "./shared/i18n/i18n";
import { ProgressSpinner } from "primereact/progressspinner";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Suspense
    fallback={
      <div className="w-screen h-screen flex justify-content-center align-items-center">
        {" "}
        <div className="center flex flex-column">
          <ProgressSpinner className="center" />
        </div>
      </div>
    }
  >
    <App />
  </React.Suspense>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
