import SignaturePad  from "react-signature-pad-wrapper";
import React, {
  forwardRef,
  useRef,
  useImperativeHandle
} from "react";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";

const MySignaturePad = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const signatureRef = useRef(null);
  

  useImperativeHandle(ref, () => ({
    clear: () => {
      signatureRef.current.clear();
    },
    toDataURL: () => {
      return signatureRef.current.toDataURL();
    },
  }));

  const handleClear = (event) => {
    event.preventDefault();
    signatureRef.current.clear();
    if(props.onClear) {
      props.onClear(props.id,null,false);
    }
  };

  const handleSave = (event) => {
    event.preventDefault();
    const data = signatureRef.current.toDataURL();
    if(props.onSave) {
      props.onSave(props.id,data,true);
    }
  };

  return (
    <div className={`flex flex-column gap-2 ${props.className}`}>
      <label htmlFor={props.id}>{props.label}</label>
      <div className="flex flex-column align-items-end gap-2">
        <SignaturePad
          ref={signatureRef}
          redrawOnResize
          canvasProps={{
            className:
              "border-solid border-round surface-border border-1 max-h-15rem",
          }}
          {...props}
        />

        <div className="flex flex-row align-items-end gap-2">
          <Button
            onClick={handleClear}
            severity="danger"
            size="small"
          >
            {t("clear")}
          </Button>
          <Button
            onClick={handleSave}
            severity="success"
            size="small"
          >
            {t("confirm")}
          </Button>
        </div>
      </div>
    </div>
  );
});


export { MySignaturePad as SignaturePad};
