export const provinces = [{code:"AG",desc: "Agrigento"},
{code:"AL",desc: "Alessandria"},
{code:"AN",desc: "ANCONA"},
{code:"BT",desc: "Andria-Barletta-Trani"},
{code:"AO",desc: "AOSTA"},
{code:"AR",desc: "Arezzo"},
{code:"AP",desc: "Ascoli Piceno"},
{code:"AT",desc: "Asti"},
{code:"AV",desc: "Avellino"},
{code:"BA",desc: "BARI"},
{code:"BL",desc: "Belluno"},
{code:"BN",desc: "Benevento"},
{code:"BG",desc: "Bergamo"},
{code:"BI",desc: "Biella"},
{code:"BO",desc: "BOLOGNA"},
{code:"BZ",desc: "Bolzano"},
{code:"BS",desc: "Brescia"},
{code:"BR",desc: "Brindisi"},
{code:"CA",desc: "CAGLIARI"},
{code:"CL",desc: "Caltanissetta"},
{code:"CB",desc: "CAMPOBASSO"},
{code:"CI",desc: "Carbonia-Iglesias"},
{code:"CE",desc: "Caserta"},
{code:"CT",desc: "Catania"},
{code:"CZ",desc: "CATANZARO"},
{code:"CH",desc: "Chieti"},
{code:"CO",desc: "Como"},
{code:"CS",desc: "Cosenza"},
{code:"CR",desc: "Cremona"},
{code:"KR",desc: "Crotone"},
{code:"CN",desc: "Cuneo"},
{code:"EN",desc: "Enna"},
{code:"FM",desc: "Fermo"},
{code:"FE",desc: "Ferrara"},
{code:"FI",desc: "FIRENZE"},
{code:"FG",desc: "Foggia"},
{code:"FC",desc: "Forlì-Cesena"},
{code:"FR",desc: "Frosinone"},
{code:"GE",desc: "GENOVA"},
{code:"GO",desc: "Gorizia"},
{code:"GR",desc: "Grosseto"},
{code:"IM",desc: "Imperia"},
{code:"IS",desc: "Isernia"},
{code:"AQ",desc: "L'AQUILA"},
{code:"SP",desc: "La Spezia"},
{code:"LT",desc: "Latina"},
{code:"LE",desc: "Lecce"},
{code:"LC",desc: "Lecco"},
{code:"LI",desc: "Livorno"},
{code:"LO",desc: "Lodi"},
{code:"LU",desc: "Lucca"},
{code:"MC",desc: "Macerata"},
{code:"MN",desc: "Mantova"},
{code:"MS",desc: "Massa"},
{code:"MT",desc: "Matera"},
{code:"ME",desc: "Messina"},
{code:"MI",desc: "MILANO"},
{code:"MO",desc: "Modena"},
{code:"MB",desc: "Monza"},
{code:"NA",desc: "NAPOLI"},
{code:"NO",desc: "Novara"},
{code:"NU",desc: "Nuoro"},
{code:"OT",desc: "Olbia-Tempio"},
{code:"OR",desc: "Oristano"},
{code:"PD",desc: "Padova"},
{code:"PA",desc: "PALERMO"},
{code:"PR",desc: "Parma"},
{code:"PV",desc: "Pavia"},
{code:"PG",desc: "PERUGIA"},
{code:"PU",desc: "Pesaro-Urbino"},
{code:"PE",desc: "Pescara"},
{code:"PC",desc: "Piacenza"},
{code:"PI",desc: "Pisa"},
{code:"PT",desc: "Pistoia"},
{code:"PN",desc: "Pordenone"},
{code:"PZ",desc: "POTENZA"},
{code:"PO",desc: "Prato"},
{code:"RG",desc: "Ragusa"},
{code:"RA",desc: "Ravenna"},
{code:"RC",desc: "Reggio Calabria"},
{code:"RE",desc: "Reggio Emilia"},
{code:"RI",desc: "Rieti"},
{code:"RN",desc: "Rimini"},
{code:"RM",desc: "ROMA"},
{code:"RO",desc: "Rovigo"},
{code:"SA",desc: "Salerno"},
{code:"SS",desc: "Sassari"},
{code:"SV",desc: "Savona"},
{code:"SI",desc: "Siena"},
{code:"SR",desc: "Siracusa"},
{code:"SO",desc: "Sondrio"},
{code:"TA",desc: "Taranto"},
{code:"TE",desc: "Teramo"},
{code:"TR",desc: "Terni"},
{code:"TO",desc: "TORINO"},
{code:"OG",desc: "Tortolì-Lanusei"},
{code:"TP",desc: "Trapani"},
{code:"TN",desc: "TRENTO"},
{code:"TV",desc: "Treviso"},
{code:"TS",desc: "TRIESTE"},
{code:"UD",desc: "Udine"},
{code:"VA",desc: "Varese"},
{code:"VE",desc: "VENEZIA"},
{code:"VB",desc: "Verbania"},
{code:"VC",desc: "Vercelli"},
{code:"VR",desc: "Verona"},
{code:"VV",desc: "Vibo Valentia"},
{code:"VI",desc: "Vicenza"},
{code:"VS",desc: "Villacidro-Sanluri"},
{code:"VT",desc: "Viterbo"}]