import React from "react";
import { FaCheck, FaCircle, FaTimes } from "react-icons/fa";
import { ProgressSpinner } from "primereact/progressspinner";

const Steps = (props) => {
  const steps = props.steps;
/*
  const steps = [
    {
      title: "TITOLO",
      subtitle: "descrizione",
      status: "DONE",
    },
    {
      title: "TITOLO",
      subtitle: "descrizione",
      status: "PROGRESS",
    },
    {
      title: "TITOLO",
      subtitle: "descrizione",
      status: "TODO",
    },
    {
      title: "TITOLO",
      subtitle: "descrizione",
      status: "ERROR",
    },
  ];
  */

  const getIcon = (step) => {
    switch (step.status) {
      case "DONE":
        return <FaCheck className="text-xl text-primary-600" />;
      case "ERROR":
        return <FaTimes className="text-xl text-red-600" />;
      case "PROGRESS":
        return (
          <ProgressSpinner
            style={{ width: "30px", height: "30px" }}
            strokeWidth="6"
            //fill="var(--primary-600)"
            //animationDuration=".5s"
          />
        );

      default:
        return <FaCircle className="text-xl text-600" />;
    }
  };

  const getBackgroundColor = (step) => {
    switch (step.status) {
      case "DONE":
      case "PROGRESS":
        return "bg-primary-100";
      case "ERROR":
        return "bg-red-200";
      default:
        return "surface-200";
    }
  };

  const getRowColor = (step) => {
    switch (step.status) {
      case "DONE":
      case "PROGRESS":
        return "bg-primary-500";
      case "ERROR":
        return "bg-red-500";
      default:
        return "surface-300";
    }
  };


  if (!steps) {
    return;
  }
  return (
    <ul className="list-none flex flex-column gap-3 lg:flex-row justify-content-between p-0">
      {steps.map((step, i) => {
        return (
          <li
            key={i}
            className="flex align-items-center flex-column gap-3 relative w-full"
          >
            <div
              className={`flex justify-content-center align-items-center border-circle ${getBackgroundColor(
                step
              )} w-4rem h-4rem z-1`}
            >
              {getIcon(step)}
            </div>

            {i !== steps.length - 1 && (
              <div
                className={`w-full hidden lg:block absolute left-50 ${getRowColor(
                  steps[i + 1]
                )} hidden`}
                style={{
                  transform: "translateY(-50%)",
                  height: "2px",
                  top: "25%",
                }}
              />
            )}
            <div className="flex flex-column align-items-center">
              <h1 className="m-0 font-medium text-900 text-xl line-height-3">
                {step.title}
              </h1>
              <span className="text-600 text-base text-center">
                {step.subtitle}
              </span>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default Steps;
