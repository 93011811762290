import React from "react";
import SuiteAppsItem from "./SuiteAppsItem";

import "./SuiteAppsList.css";

const SuiteAppsList = (props) => {
  if (!props.items || props.items.length === 0) {
    return <span>No apps</span>;
  }

  return (
    <ul className="suite-apps-list flex flex-row flex-wrap justify-content-center gap-4">
      {props.items.map((app) => (
        <SuiteAppsItem
          key={app.function_id}
          title={app.title}
          applicationId={app.application_id}
          functionId={app.function_id}
        />
      ))}
    </ul>
  );
};

export default SuiteAppsList;
