import React from "react";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { locale } from "primereact/api";

const LanguageMenu = (props) => {
  const { i18n } = useTranslation();
  const languages = ["it", "en"];

  const changeLanguageHandler = (lng) => {
    i18n.changeLanguage(lng, () =>{
      //calback cambio lingua i18n

      locale(lng); //aggiorno la lingua dei componenti primeReact caricati in i18n.js
    });
  };

  return (
    <div className={`flex flex-row ml-3 mr-3 ${props.className}`}>
      {languages.map((language, i) => (
        <Button
          label={language.toUpperCase()}
          key={language}
          link
          className={`flex-1 p-0 ${
            i18n.resolvedLanguage === language ? "opacity-100" : "opacity-60"
          } ${props.buttonClassName ? props.buttonClassName : "text-white"}`}
          onClick={() => changeLanguageHandler(language)}
          disabled={i18n.resolvedLanguage === language}
        />
      ))}
    </div>
  );
};

export default LanguageMenu;
