import React from "react";

import "./LoadingSpinner.css";
import { ProgressSpinner } from "primereact/progressspinner";

const LoadingSpinner = (props) => {
  return (
    <div className={`${props.asOverlay && "loading-spinner__overlay"}`}>
      <ProgressSpinner className="flex justify-content-center text-center" />
    </div>
  );
};

export default LoadingSpinner;
