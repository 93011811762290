import React, { useContext } from "react";
import { Dialog } from "primereact/dialog";

import { AuthContext } from "../../shared/context/auth-context";
import SuiteAppsList from "../components/SuiteAppsList";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

const SuiteApps = (props) => {
  const auth = useContext(AuthContext);

  const dialogHeader = (
    <div
      className="text-center"
      style={{ color: "var(--highlight-text-color)" }}
    >
      4WS Suite Apps
    </div>
  );

  return (
    <React.Fragment>
      
      <Dialog
        header={dialogHeader}
        visible={props.visible}
        style={{ width: "40vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => props.setVisible(false)}
        dismissableMask
      >
        {auth.isLoadingUserData && (
          <div className="center">
            <LoadingSpinner />
          </div>
        )}

        {!auth.isLoadingUserData && auth.apps && <SuiteAppsList items={auth.apps} />}
      </Dialog>
    </React.Fragment>
  );
};

export default SuiteApps;
