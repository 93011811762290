import React from "react";
import { Button } from "primereact/button";
import { FaMicrosoft } from "react-icons/fa";

const SuiteAppsButton = (props) => {

  return (
    <Button
      className={`flex flex-column  m-2 ${props.className}`}
      style={{
        backgroundColor: "var(--highlight-text-color)",
      }}
      onClick={props.onClick}
    >
      <FaMicrosoft className="text-xl" />
      <span className="font-medium text-sm lg:text-xs">Apps</span>
    </Button>
  );
};

export default SuiteAppsButton;
